import { type EffectCallback, useEffect, useRef } from 'react';

/**
 * Runs an effect only on component mount.
 * Useful for effects we don't want to rerun even if their dependencies change, e.g., analytics events that fire on screen load.
 */
export const useMountEffect = (effect: EffectCallback) => {
	const initialRun = useRef(true);
	const cleanupFn = useRef<ReturnType<EffectCallback>>(undefined);
	useEffect(() => {
		if (initialRun.current) {
			initialRun.current = false;
			cleanupFn.current = effect();
		}
		return cleanupFn.current;
	}, [effect]);
};
