import type { FC } from 'react';
import React from 'react';
import { styled } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import AkChevronRightIcon from '@atlaskit/icon/glyph/chevron-right';
import DragHandlerIcon from '@atlaskit/icon/glyph/drag-handler';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';

import type { ContentTreeItem } from './data-transformers';
import { PageTreeItemContent } from './PageTreeItem';

const i18n = defineMessages({
	childrenCount: {
		id: 'page-tree.children-count-plural',
		defaultMessage: `{count, plural,
      one { includes # child page }
      other { includes # child pages }
    }`,
		description:
			'Used for contextual information. Tells the user how many children does a page have. Use ICU format for pluralization.',
	},
	untitledDraftText: {
		id: 'page-tree.move-dialog.untitled-draft',
		defaultMessage: 'Untitled',
		description: 'A placeholder for drafts that have no title',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DragPreviewContainer = styled.div<{
	isMovePageDialog?: boolean;
}>({
	'--grid': '8px',
	padding: 'var(--grid) calc(var(--grid) * 2)',
	display: 'flex',
	alignItems: 'center',
	borderRadius: 3,
	boxSizing: 'border-box', // we want the width to apply to the border box, and not the content box (default)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props) => (props.isMovePageDialog ? '450px' : '210px')}`,
	background: token('elevation.surface.raised', N0),
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BeforeIcon = styled.span({
	display: 'flex',
	width: '24px',
	height: '24px',
	justifyContent: 'center',
	alignItems: 'center',
	marginRight: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DotIcon = styled.div({
	height: '3px',
	lineHeight: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TitleWrapper = styled.div({
	flex: '1 1 auto',
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ChildrenCountContainer = styled.span({
	flex: '0 0 auto',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
	marginLeft: '10px',
});

export type DragPreviewProps = {
	item: ContentTreeItem;
	type?: 'move-page-tree';
	childPagesCount?: number;
};

export const DragPreview: FC<DragPreviewProps> = ({ item, type, childPagesCount }) => {
	const intl = useIntl();
	const draftText = intl.formatMessage(i18n.untitledDraftText);
	const isMovePageDialog = type === 'move-page-tree';

	let beforeIcon = <DotIcon>&bull;</DotIcon>;
	if (isMovePageDialog) {
		beforeIcon = <DragHandlerIcon label="" primaryColor={token('color.icon', '#42526E')} />;
	} else if (item.hasChildren || item.data.type === 'folder') {
		beforeIcon = <AkChevronRightIcon label="" size="medium" />;
	}

	return (
		<DragPreviewContainer isMovePageDialog={isMovePageDialog}>
			<BeforeIcon>{beforeIcon}</BeforeIcon>
			<TitleWrapper>
				<PageTreeItemContent
					id={item.id.toString()}
					displayHomePageIcon={false}
					displayEmoji={item.data.emoji}
					displayTitle={item.data.title || draftText}
					isDraft={item.data.status === 'DRAFT'}
					contentType={item.data.type}
					draggableState="idle"
					isSelected={false}
				/>
			</TitleWrapper>
			{!!childPagesCount && childPagesCount > 0 && (
				<ChildrenCountContainer>
					<DocumentsIcon label="" size="small" />{' '}
					<FormattedMessage {...i18n.childrenCount} values={{ count: childPagesCount }} />
				</ChildrenCountContainer>
			)}
		</DragPreviewContainer>
	);
};
