import { useEffect, useState } from 'react';

import { isPersonalSpace as checkIsPersonalSpace } from '@confluence/space-utils';
import {
	useGetOnboardingState,
	useOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';

import { useSampleContentExperiment } from './useSampleContentExperiment';

interface UseSpotlightProps {
	spaceKey?: string;
	isTargetReady: boolean;
}
export const useSpotlight = ({ spaceKey, isTargetReady }: UseSpotlightProps) => {
	const { setOnboardingState } = useOnboardingState();
	const { isUserInTreatment } = useSampleContentExperiment();
	const { data: onboardingStateData } = useGetOnboardingState([
		ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_SPOTLIGHT_VISIBLE,
	]);
	const { isSampleContentSpotlightVisible } = deserializeState(onboardingStateData);
	const [isSpotlightVisible, setIsSpotlightVisible] = useState(false);
	const isPersonalSpace = checkIsPersonalSpace(spaceKey);
	useEffect(() => {
		if (isUserInTreatment && isSampleContentSpotlightVisible && isTargetReady && isPersonalSpace) {
			setTimeout(() => {
				setIsSpotlightVisible(true);
				setOnboardingState({
					key: ONBOARDING_EXPERIENCES.IS_SAMPLE_CONTENT_SPOTLIGHT_VISIBLE,
					value: 'false',
				});
			}, 2000);
		}
	}, [
		isTargetReady,
		setIsSpotlightVisible,
		isPersonalSpace,
		setOnboardingState,
		isUserInTreatment,
		isSampleContentSpotlightVisible,
	]);
	const dismiss = () => {
		setIsSpotlightVisible(false);
	};
	return {
		isSpotlightVisible,
		dismiss,
	};
};
