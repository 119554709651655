import { useCallback } from 'react';
import type { ApolloQueryResult } from 'apollo-client';

import { mergeChildren } from './data-extractors';
import { PageTreeChildrenQuery } from './queries/PageTreeChildrenQuery.graphql';
import { CHILDREN_PAGE_SIZE } from './paginationLimits';
import { usePageTreeState } from './usePageTreeState';
import { useImperativeQuery } from './useImperativeQuery';
import type { GraphQLPageStatus } from './pageTreeStatuses';
import type { PageTreeChildrenQuery as PageTreeChildrenQueryType } from './queries/__types__/PageTreeChildrenQuery';

type UsePageTreeChildren = ({
	statuses,
	isSuperAdmin,
}: {
	statuses?: GraphQLPageStatus[];
	isSuperAdmin?: boolean;
}) => {
	queryChildren: (
		pageId: any,
		after: string,
		allChildren: ReturnType<typeof mergeChildren>,
	) => Promise<ReturnType<typeof mergeChildren> | void>;
	reloadChildren: () => void;
};

export const usePageTreeChildren: UsePageTreeChildren = ({ statuses, isSuperAdmin }) => {
	const [state, actions] = usePageTreeState();

	const getPageChildren = useImperativeQuery<any, any>(PageTreeChildrenQuery);

	const queryChildren = useCallback(
		(pageId: any, after: any = undefined, allChildren: any = { count: 0, nodes: [] }) => {
			return getPageChildren({
				contentId: pageId,
				statuses,
				first: CHILDREN_PAGE_SIZE,
				after,
				isSuperAdmin: isSuperAdmin || false,
			})
				.then((response: ApolloQueryResult<PageTreeChildrenQueryType>) => {
					if (response.data) {
						const children = response.data?.ptpage?.children;
						const mergedChildren = children ? mergeChildren(allChildren, children) : undefined;
						return children?.pageInfo?.hasNextPage
							? queryChildren(pageId, children?.pageInfo?.endCursor, mergedChildren)
							: mergedChildren;
					}
				})
				.catch((error) => {
					actions.handleLoadingError(error, 'children');
					return { error };
				});
		},
		[getPageChildren, isSuperAdmin, statuses, actions],
	);

	const reloadChildren = useCallback(() => {
		// @ts-ignore
		void queryChildren(state.focusedPageId);
		// @ts-ignore
	}, [queryChildren, state.focusedPageId]);

	return { queryChildren, reloadChildren };
};
