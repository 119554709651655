import FeatureGates from '@atlaskit/feature-gate-js-client';

export const useSampleContentExperiment = () => {
	const getIsUserInTreatment = () => {
		const experimentVal: string = FeatureGates.getExperimentValue(
			'cfind-2079_create_sample_content',
			'cohort',
			'control',
			{ fireExperimentExposure: false }, // Experiment exposure is fired in the backend
		);

		return experimentVal === 'test';
	};

	return {
		isUserInTreatment: getIsUserInTreatment(),
	};
};
