import { createStore, createHook, createContainer, createSelector } from 'react-sweet-state';
import produce from 'immer';
import type { ApolloError } from 'apollo-client';
import uniq from 'lodash/uniq';

import { isSpaceRestrictedError } from '@confluence/restrictions';
import { markErrorAsHandled } from '@confluence/graphql';
import { isExpectedApolloError } from '@confluence/error-boundary';
import { isRecentPublishedPage } from '@confluence/page-utils/entry-points/unreadPages';
import { moveItemOnTree, addChild, removeChild } from '@confluence/tree/entry-points/treeMutations';
import type { ItemId, TreeObjectItems, TreeObject } from '@confluence/tree';

import type { ContentTreeItem } from './data-transformers';
import {
	createTreeItem,
	derriveStateFromData,
	setChildren,
	transformUIAttributes,
} from './data-transformers';
import { childrenAreLatest, getAncestryIds, getIds, getPages, mergePages } from './data-extractors';

export const initialPageTreeState: PageTreeState = {
	expandedPages: new Set(),
	loadingPages: new Set(),
	pages: {},
	space: null,
	spaceKey: null,
	rootId: null,
	isTreeTruncatedPrevious: false,
	isTreeTruncatedFollowing: false,
	cursors: {},
	initialPages: {},
	error: null,
	blogPeekRoot: undefined,
	focusedPageId: null,
	isPageTreeLoading: true,
	isShowingBlankDraftsEnabled: false,
	isUnreadPagesEnabled: false,
	visitedPages: new Set(),
	showCreateFolderSurvey: false,
};

export let persistedPageTreeStateObject: PageTreeState = initialPageTreeState;

export type PageTreeState = {
	expandedPages: Set<ItemId>;
	loadingPages: Set<ItemId>;
	pages: TreeObjectItems<ContentTreeItem>;
	space: {
		id: string;
		homepage: { id: string };
		operations: Array<{ targetType: string; operation: string }> | null;
	} | null;
	spaceKey?: string | null | undefined;
	rootId: ItemId | null;
	isTreeTruncatedPrevious: boolean;
	isTreeTruncatedFollowing: boolean;
	cursors: object;
	initialPages: object;
	isPageTreeLoading: boolean;
	error?:
		| {
				apolloError: ApolloError;
				isExpected?: boolean;
				location?: loadingErrorLocation;
		  }
		| null
		| undefined;
	blogPeekRoot?: string | undefined;
	focusedPageId: string | null;
	isShowingBlankDraftsEnabled?: boolean;
	isUnreadPagesEnabled?: boolean;
	visitedPages: Set<ItemId>;
	showCreateFolderSurvey: boolean;
};

export type loadingErrorLocation = 'following' | 'previous' | 'children';

export const shouldUsePersistence = (spaceKey: string) =>
	Object.keys(persistedPageTreeStateObject.pages).length > 0 && //there are pages in the package level variable
	persistedPageTreeStateObject.spaceKey === spaceKey; //and the persisted state has the same spacekey as the props

export const actions = {
	initializeState:
		() =>
		(
			{ setState, dispatch },
			{
				currentPageId,
				spaceKey,
				highlightPages = [],
				initialData: data,
				isPeekingFromBlogs,
				syntheticItem,
				syntheticItemIsDraft,
				detached,
				isShowingBlankDraftsEnabled = false,
				isUnreadPagesEnabled = false,
				visitedPages = new Set(),
			},
		) => {
			if (shouldUsePersistence(spaceKey) && !detached) {
				setState({
					...persistedPageTreeStateObject,
					pages: transformUIAttributes(
						persistedPageTreeStateObject.pages,
						currentPageId,
						persistedPageTreeStateObject.expandedPages,
						persistedPageTreeStateObject.loadingPages,
						getUnreadPages(persistedPageTreeStateObject),
						highlightPages,
					),
					isShowingBlankDraftsEnabled,
					isUnreadPagesEnabled,
					visitedPages,
				});
			} else if (data && Object.keys(data).length > 0) {
				const stateFromData = derriveStateFromData(
					data,
					spaceKey,
					{
						...initialPageTreeState,
						expandedPages: new Set(),
						loadingPages: new Set(),
						unreadPages: new Set(),
						visitedPages: new Set(),
					},
					isPeekingFromBlogs,
					syntheticItem,
					syntheticItemIsDraft,
					isShowingBlankDraftsEnabled,
					isUnreadPagesEnabled,
				);
				dispatch(actions.setStateWithUIAttributes(stateFromData));
				dispatch(actions.setIsPageTreeLoading(false));
			} else {
				setState({
					...initialPageTreeState,
					expandedPages: new Set(),
					loadingPages: new Set(),
					unreadPages: new Set(),
					visitedPages: new Set(),
					spaceKey,
					isShowingBlankDraftsEnabled,
					isUnreadPagesEnabled,
				});
			}
		},
	getState:
		() =>
		({ getState }) => {
			return getState();
		},
	//only fires when props change on the container
	derivedStateFromPropsReplacement:
		() =>
		(
			{ dispatch, getState },
			{ currentPageId, highlightPages = [], spaceKey, visitedPages = new Set() },
		) => {
			//should really check if any of these props changed
			const { pages, expandedPages, loadingPages } = getState();

			const unreadPages = getUnreadPages(getState());
			dispatch(
				actions.setStateWithUIAttributes({
					pages: transformUIAttributes(
						pages,
						currentPageId,
						expandedPages,
						loadingPages,
						unreadPages,
						highlightPages,
					),
					spaceKey,
					visitedPages,
				}),
			);
		},
	updatePersistedState:
		() =>
		({ getState }, { detached }) => {
			if (!detached) {
				//don't save state if we're in the move page dialog
				const state = getState();
				persistedPageTreeStateObject = state;
			}
		},
	setStateWithUIAttributes:
		(stateObj) =>
		({ setState, getState, dispatch }, { currentPageId, highlightPages = [] }) => {
			//this is a brute force access to state, it kinda doesn't follow the conventions of sweet state...buuut the alternative is a lot of very samey setters
			setState({ ...stateObj });
			const { pages, expandedPages, loadingPages } = getState();
			const unreadPages = getUnreadPages(getState());
			//this function allows us to transformUIAttributes universally on update
			//toDo: move these properties to state and simply read the sweet state via hooks lower in the tree rather than updating the pages state
			setState({
				//this is
				pages: transformUIAttributes(
					pages,
					currentPageId,
					expandedPages,
					loadingPages,
					unreadPages,
					highlightPages,
				),
			});
			dispatch(actions.setFocusPageId());
		},
	addLoadingPage:
		(pageId: string) =>
		({ getState, dispatch }) => {
			dispatch(
				actions.setStateWithUIAttributes({
					loadingPages: getState().loadingPages.add(pageId),
				}),
			);
		},
	setIsPageTreeLoading:
		(bool: Boolean) =>
		({ setState }) => {
			setState({ isPageTreeLoading: bool });
		},
	setIsTreeTruncated:
		(bool: Boolean) =>
		({ setState }) => {
			setState({ isTreeTruncated: bool });
		},
	setIsTreeTruncatedPrevious:
		(bool: Boolean) =>
		({ setState }) => {
			setState({ isTreeTruncatedPrevious: bool });
		},
	setIsTreeTruncatedFollowing:
		(bool: Boolean) =>
		({ setState }) => {
			setState({ isTreeTruncatedFollowing: bool });
		},
	deleteLoadingPage:
		(pageId: string) =>
		({ getState, dispatch }) => {
			const { loadingPages } = getState();
			loadingPages.delete(pageId);
			dispatch(actions.setStateWithUIAttributes({ loadingPages }));
		},
	expandPage:
		(page: ContentTreeItem, queryChildren) =>
		async ({ getState, dispatch }, { onExpand }) => {
			const { pages, expandedPages, loadingPages, space } = getState();
			const expandedPage = pages[page.id];
			if (
				expandedPages.has(page.id) ||
				(!expandedPage.hasChildren && expandedPage.data.type !== 'folder')
			) {
				return;
			}

			const mergedExpansions = [page.id, ...getAncestryIds(page.id, pages, expandedPages)];

			if (expandedPage.hasChildren && !childrenAreLatest(expandedPage)) {
				dispatch(
					actions.setStateWithUIAttributes({
						loadingPages: loadingPages.add(expandedPage.id),
					}),
				);

				const children = {};

				const { isShowingBlankDraftsEnabled } = getState();

				const { nodes, error } = await queryChildren(expandedPage.id);

				if (error) {
					//toDo: handle loading children error here, then reset state so they can attempt to re-expand
					return;
				}

				const queriedChildren = getPages({ nodes }, isShowingBlankDraftsEnabled);
				queriedChildren.forEach((child) => {
					children[child.id] = createTreeItem(child, isShowingBlankDraftsEnabled);
				});

				// It's possible for the childen to change locally (via DnD) while the queryChildren request is in-flight.
				// Get the latest children now (after the request finished) to prevent a loss of state.
				const latestPages = getState().pages;
				const latestChildren = latestPages[expandedPage.id].children;
				latestChildren.forEach((id) => {
					children[id] = latestPages[id];
				});

				const childIds = uniq([...getIds(queriedChildren), ...latestChildren]);

				let allPages = mergePages(latestPages, children);
				allPages = produce(allPages, (editedAllPages) => {
					// @ts-ignore hasAdditionalChildren not available in AK tree. Can remove when using pragmatic types.
					editedAllPages[expandedPage.id].hasAdditionalChildren = false;
				});

				loadingPages.delete(expandedPage.id);
				const finalPages = setChildren(allPages, expandedPage.id, childIds);
				dispatch(
					actions.setStateWithUIAttributes({
						//use dispatch to make sure that if special properties are changed the tree is re-jiggered
						loadingPages,
						pages: finalPages,
					}),
				);

				if (onExpand) {
					onExpand(finalPages[expandedPage.id], { spaceId: space?.id });
				}
			}
			dispatch(
				actions.setStateWithUIAttributes({
					expandedPages: new Set(mergedExpansions),
				}),
			);
		},
	expandSpaceHomepage:
		() =>
		({ getState, dispatch }) => {
			const { pages, expandedPages, space } = getState();
			const homepageId = space?.homepage?.id;
			if (!homepageId) {
				return;
			}
			// the home page might still not be loaded in some weirder cases
			const expandedPage = pages[homepageId];
			if (expandedPages.has(homepageId) || !expandedPage?.hasChildren) {
				return;
			}

			const mergedExpansions = [homepageId, ...getAncestryIds(homepageId, pages, expandedPages)];
			dispatch(
				actions.setStateWithUIAttributes({
					expandedPages: new Set(mergedExpansions),
				}),
			);
		},
	collapsePage:
		(page: ContentTreeItem) =>
		({ getState, dispatch }, { onCollapse }) => {
			const { expandedPages, space } = getState();
			expandedPages.delete(page.id);
			dispatch(actions.setStateWithUIAttributes({ expandedPages }));
			if (onCollapse) {
				onCollapse(page, { spaceId: space?.id });
			}
		},
	movePageInState:
		(pageId: ItemId, source, destination, queryChildren, expandAfter?: boolean) =>
		({ getState, dispatch }) => {
			const { rootId, pages, expandedPages } = getState();

			let newTree: TreeObject<ContentTreeItem> = {
				rootId,
				items: pages,
			};
			const destinationParent = newTree.items[destination.parentId];
			if (source.index === undefined) {
				newTree = removeChild(newTree, source.parentId, pageId);
				newTree = addChild(newTree, destination.parentId, pageId, destination.index);
			} else {
				newTree = moveItemOnTree<ContentTreeItem>(newTree, source, destination);
			}

			if (newTree.items[source.parentId].children.length === 0) {
				expandedPages.delete(source.parentId);
			}

			dispatch(
				actions.setStateWithUIAttributes({
					pages: newTree.items,
					rootId: newTree.rootId,
					expandedPages,
				}),
			);
			if (expandAfter) {
				dispatch(actions.expandPage(destinationParent, queryChildren));
			}
		},
	handleLoadingError:
		(apolloError: ApolloError | undefined, location?: loadingErrorLocation) =>
		({ setState }, { onLoadError }) => {
			if (!apolloError) {
				return;
			}

			const errorState: PageTreeState['error'] = {
				apolloError,
				location,
			};

			if (isSpaceRestrictedError(apolloError) || isExpectedApolloError(apolloError)) {
				// Until we have a proper "restricted" state, we will show the
				// error state in case of restricted error, but mark the error
				// as expected so the experience will still succeed
				markErrorAsHandled(apolloError);
				errorState.isExpected = true;
			}

			//todo: to handle loading children and previous next errors without causing the whole tree to crash we may want to wrap this in if(location)
			//however that means the error will not be reported and we need a programatic way of ding that. OR new places to render the error handler at the top / bottom/ as a child of tree elements
			setState({
				error: errorState,
			});

			if (onLoadError) {
				onLoadError(apolloError, {
					isExpected: errorState.isExpected,
					location: errorState.location,
				});
			}
		},
	updateSinglePage:
		(id: string, data: object) =>
		({ getState, dispatch }) => {
			const { pages } = getState();
			const pageToUpdate = {
				...pages[id],
				data: { ...pages[id].data, ...data },
			};
			dispatch(
				actions.setStateWithUIAttributes({
					pages: { ...pages, [id]: pageToUpdate },
				}),
			);
		},
	setFocusPageId:
		() =>
		({ getState, setState }, { currentPageId, highlightPages = [] }) => {
			const { blogPeekRoot } = getState();
			const focusedPageId = blogPeekRoot || currentPageId || highlightPages[0];

			setState({ focusedPageId });
		},
	getCurrentTreeSize:
		() =>
		({ getState }) => {
			const { pages } = getState();
			return Object.keys(pages).length;
		},
	setShowCreateFolderSurvey:
		(bool: Boolean) =>
		({ setState }) => {
			setState({ showCreateFolderSurvey: bool });
		},
};

export const getUnreadPages = createSelector<
	PageTreeState,
	void,
	Set<string>,
	boolean,
	TreeObjectItems<ContentTreeItem>,
	Set<string>
>(
	(state) => !!state.isUnreadPagesEnabled,
	(state) => state.pages,
	(state) => state.visitedPages,
	(isUnreadPagesEnabled, pages, visitedPages) => {
		const unreadPages = new Set<string>();

		if (!isUnreadPagesEnabled) {
			return unreadPages;
		}

		Object.values(pages).forEach((page) => {
			const { createdDate, status, type: contentType } = page.data;
			if (
				visitedPages?.size &&
				!visitedPages.has(page.id) &&
				isRecentPublishedPage({
					contentType,
					createdDate: createdDate ? new Date(createdDate) : undefined,
					status,
				})
			) {
				unreadPages.add(page.id);
			}
		});
		return unreadPages;
	},
);

const Store = createStore({
	// @ts-ignore
	initialState: {
		...initialPageTreeState,
		expandedPages: new Set(),
		loadingPages: new Set(),
	},
	actions,
	name: 'pageTreeState',
});

//use as a component & pass in these props
// currentPageId,
// highlightPages,
// spaceKey,
// syntheticItem,
// syntheticItemIsDraft,
// onExpand,
// onCollapse
// onLoadError,
// isPeekingFromBlogs,
// initialData
// isShowingBlankDraftsEnabled
export const PageTreeStateContainer = createContainer(Store, {
	onInit: actions.initializeState,
	// @ts-ignore
	onUpdate: actions.derivedStateFromPropsReplacement,
});

//state hook
export const usePageTreeState = createHook(Store);
